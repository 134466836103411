<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
              <!-- Purchase From Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="start_from_date"
                    v-model="search_starting_date"
                    id="start_from_date"
                    placeholder="Start Date From"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

              <!-- Purchase to Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                    format="yyyy-MM-dd"
                    type="date"
                    name="start_to_date"
                    v-model="search_ending_date"
                    id="start_to_date"
                    placeholder="Start Date To"
                    :clearable="true"
                    style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Teacher</th>
                  <th>Batch</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(batch, index) in batches.data"
                  :key="index"
                  v-if="batches.data.length"
                >
                  <td class="align-middle text-center">
                    {{ batches.from + index }} - {{ batch.id }}
                  </td>
                  <td class="align-middle">
                    <b>{{ batch.name }}</b>
                  </td>

                  <td>
                    <p class="mb-0">{{ batch.course.name }}</p>
                  </td>
                  <td>
                    <p class="mb-0">{{ batch.teacher.name }}</p>
                  </td>
                  <td>
                    <p class="mb-0"><b>Start : </b> {{ batch.start_date }}</p>
                    <p class="mb-0"><b>End : </b> {{ batch.end_date }}</p>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(batch.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </button>
                    <button
                      class="btn btn-danger btn-sm ml-1"
                      @click="Delete(batch.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </button>
                    <router-link :to="'/batch/' + batch.id">
                      <button class="btn btn-info btn-sm ml-1">
                        <feather-icon icon="EyeIcon" />
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ batches.from ? batches.from : 0 }} to
                  {{ batches.to ? batches.to : 0 }} of
                  {{ batches.total ? batches.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="batches"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Batch'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="batch Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="batch name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Batch Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Select Course" label-for="course_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Course"
                      >
                        <v-select
                          id="course_id"
                          v-model="form.course_id"
                          placeholder="Select Course"
                          :options="courses"
                          label="name"
                          :reduce="(course) => course.id"
                        >
                          <template #course="{ name }">
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Select Teacher" label-for="teacher_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Teacher"
                      >
                        <v-select
                          id="teacher_id"
                          v-model="form.teacher_id"
                          placeholder="Select Teacher"
                          :options="teachers"
                          label="name"
                          :reduce="(teacher) => teacher.id"
                        >
                          <template #category="{ name }">
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <b-form-group
                      label="Select Lesson Assign type"
                      label-for="lesson_type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Select Teacher"
                      >
                        <v-select
                          id="lesson_type"
                          v-model="form.lesson_type"
                          placeholder="Select Lesson Assign type"
                          :options="lessonType"
                          label="name"
                          :reduce="(lessonType) => lessonType.id"
                        >
                          <template #lessonType="{ name }">
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Select Batch Start Date"
                      label-for="start_date"
                    >
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <DatePicker
                          format="yyyy-MM-dd"
                          type="date"
                          name="start_date"
                          v-model="starting_date"
                          id="start_date"
                          placeholder="Start Date To"
                          :clearable="true"
                          style="width: 100%"
                        ></DatePicker>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Select Batch End Date"
                      label-for="end_date"
                    >
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <DatePicker
                          format="yyyy-MM-dd"
                          type="date"
                          name="end_date"
                          v-model="ending_date"
                          id="end_date"
                          placeholder="Start Date To"
                          :clearable="true"
                          style="width: 100%"
                        ></DatePicker>
                      </div>
                    </b-form-group>
                  </b-col>
                </div>
                <!-- submit buttons -->

                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        start_date: "",
        end_date: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        name: "",
        course_id: "",
        teacher_id: "",
        lesson_type: "",
        start_date: "",
        end_date: "",
      },
      starting_date: null,
      ending_date: null,
      search_starting_date: null,
      search_ending_date: null,

      batches: [],
      lessonType: [
        { id: 1, name: "Auto" },
        { id: 2, name: "Manual" },
      ],
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
    this.$store.dispatch("getTeacher");
    this.$store.dispatch("getActiveCourses");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.start_date = this.formatDate(this.search_starting_date);
      this.search.end_date = this.formatDate(this.search_ending_date);
      const queryParams = this.buildQueryParamString(this.search);
      const batches = await this.callApi(
        "get",
        `/app/student/batch${queryParams}`
      );
      if (batches.status == 200) {
        this.batches = batches.data.batches;
      }
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/app/student/batch/" + id).then((res) => {
            this.getResults();
            this.Close();
            this.select.data = [];
            this.s(res.data.message);
          });
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.course_id = "";
      this.form.teacher_id = "";
      this.form.lesson_type = "";
      this.form.start_date = "";
      this.form.end_date = "";
      this.starting_date = "";
      this.ending_date = "";
    },

    async edit(id) {
      const batch = await this.callApi("get", "/app/student/batch/" + id);
      if (batch.status == 200) {
        this.form.id = batch.data.batch.id;
        this.form.name = batch.data.batch.name;
        this.form.course_id = batch.data.batch.course_id;
        this.form.teacher_id = batch.data.batch.teacher_id;
        this.form.lesson_type = batch.data.batch.lesson_type;
        this.starting_date = batch.data.batch.start_date;
        this.ending_date = batch.data.batch.end_date;
      }
    },

    update() {
      this.form.start_date = this.formatDate(this.starting_date);
      this.form.end_date = this.formatDate(this.ending_date);
      axios
        .put("/app/student/batch/" + this.form.id, this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_id)
              this.e(e.response.data.errors.course_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
          }
        });
    },

    add() {
      const fd = new FormData();
      this.form.start_date = this.formatDate(this.starting_date);
      this.form.end_date = this.formatDate(this.ending_date);
      fd.append("id", this.form.id);
      if (this.form.course_id) {
        fd.append("course_id", this.form.course_id);
      }
      fd.append("name", this.form.name);
      fd.append("teacher_id", this.form.teacher_id);
      fd.append("lesson_type", this.form.lesson_type);
      fd.append("start_date", this.form.start_date);
      fd.append("end_date", this.form.end_date);

      axios
        .post("/app/student/batch", fd)
        .then((res) => {
          this.getResults();
          this.$store.dispatch("getMainCategory");
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_id)
              this.e(e.response.data.errors.course_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            this.update();
          } else {
            this.add();
          }
        }
      });
    },
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "batch.access") {
          this.permission.access = true;
        }

        if (permission.slug == "batch.create") {
          this.permission.create = true;
        }

        if (permission.slug == "batch.update") {
          this.permission.edit = true;
        }

        if (permission.slug == "batch.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    search_starting_date: function (val) {
      this.SearchData();
    },
    search_ending_date: function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    courses() {
      return this.$store.getters.getActiveCourses;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>

<style></style>
